import {
  axiosPost,
  axiosFetch,
  axiosPatch,
  axiosDelete,
  axiosDownload,
  axiosGetBuildFile
} from "./axios-rest-methods";
import authConfig from "../config/auth";

// "GET" Requests
export const getMe = () => {
  return axiosFetch(authConfig.meEndpoint);
};
export const fetchAllUsers = (params) => {
  return axiosFetch("user", { params });
};
// export const fetchAllUsersWithToken = (params) => {
//   return axiosFetch("site-settings", { params });
// };
export const fetchSingleUser = (id) => {
  return axiosFetch(`user/${id}`);
};
export const getBuildFile = () => {
  return axiosGetBuildFile();
};
// export const getAdAccounts = () => {
//   return axiosFetch(`facebook/ad-accounts`);
// };

export const fetchMissingInfoCount = (params) => {
  return axiosFetch(`missing-info/count`, { params });
};

export const fetchMissingLeadsInfo = (params) => {
  return axiosFetch(`missing-info/leads`, { params });
};

export const fetchMissingCategoryInfo = (params) => {
  return axiosFetch(`missing-info/category`, { params });
};

export const fetchMissingCustomerInfo = (params) => {
  return axiosFetch(`missing-info/customer`, { params });
};

export const fetchMissingHubspotInfo = (params) => {
  return axiosFetch(`missing-info/hubspot`, { params });
};
export const fetchMissingAdAccountInfo = (params) => {
  return axiosFetch(`missing-info/ad-account`, { params });
};
export const fetchLeadsWithMissingUTM = (params) => {
  return axiosFetch(`missing-info/missing-utm-parameters`, { params });
};

export const getCallInsights = (queryParams = {}) => {
  return axiosFetch("lead-response/analytics/call-insights", {
    params: queryParams
  });
};
export const getAppointmentEmailTemplates = () => {
  return axiosFetch("leadservice-panel/email-template");
};

export const fetchFbAdLeadsByFunnelId = (funnelId, queryParams = {}) => {
  return axiosFetch(`lead-response/fb-ad-leads/${funnelId}`, {
    params: queryParams
  });
};

export const getLeadSyncRate = (queryParams = {}) => {
  return axiosFetch(`lead-response/analytics/lead-sync-rate`, {
    params: queryParams
  });
};

export const getAllFunnels = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("funnel", { params: queryParams });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getFunnelQuestion = (funnelId, questionId) => {
  return axiosFetch(`funnel/${funnelId}/question/${questionId}`);
};
export const getAllFunnelsForSearch = async (queryParams = {}) => {
  return axiosFetch(`funnel/search`, { params: queryParams });
};
export const getAllFunnelsEvaluation = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("funnels-evaluation", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllAdCreativesEvaluation = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("ads-evaluation", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAdCreativeInsights = async (adId) => {
  return axiosFetch(`ads-evaluation/creative-insights/${adId}`);
};
//TODO: Verify before removing it.
export const fetchCampaignOptimization = async (queryParams = {}) => {
  try {
    const response = await axiosFetch(`funnels-evaluation/optimization`, {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllCampaignsEvaluation = async (funnelId, queryParams = {}) => {
  try {
    const response = await axiosFetch(`funnels-evaluation/${funnelId}`, {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllAdsetsEvaluation = async (
  // funnelId,
  campaignId,
  queryParams = {}
) => {
  try {
    const response = await axiosFetch(
      // `funnels-evaluation/${funnelId}/${campaignId}`,
      `funnels-evaluation/${campaignId}`,
      {
        params: queryParams
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllAdsEvaluation = async (
  // funnelId,
  campaignId,
  adsetId,
  queryParams = {}
) => {
  try {
    const response = await axiosFetch(
      // `funnels-evaluation/${funnelId}/${campaignId}/${adsetId}`,
      `funnels-evaluation/${campaignId}/${adsetId}`,
      {
        params: queryParams
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllFunnelsEvaluationInsights = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("funnels-evaluation/insights", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllTemplates = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("template", { params: queryParams });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getFunnelById = async (funnelId) => {
  try {
    const response = await axiosFetch(`funnel/${funnelId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getFunnelVersionsById = async (funnelId) => {
  try {
    const response = await axiosFetch(`funnel/${funnelId}/versions`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getTemplateById = async (templateId) => {
  try {
    const response = await axiosFetch(`template/${templateId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getFunnelSiteInfo = async (queryParams) => {
  try {
    const response = await axiosFetch("funnel/site", { params: queryParams });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const fetchFunnelInsights = async (funnelId, queryParams = {}) => {
  try {
    const response = await axiosFetch(`funnel-insight/${funnelId}`, {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const fetchBlockedPostalCodes = async (funnelId) => {
  try {
    const response = await axiosFetch(`blocked-postal-code/${funnelId}`, {});
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const fetchBlockedPostalCodesCount = async (funnelId) => {
  try {
    const response = await axiosFetch(
      `blocked-postal-code/count/${funnelId}`,
      {}
    );
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const fetchFunnelInsightVersions = async (
  funnelId,
  queryParams = {}
) => {
  try {
    const response = await axiosFetch(`funnel-insight/${funnelId}/versions`, {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const fetchLeadResponseInsight = async (funnelId, queryParams = {}) => {
  try {
    const response = await axiosFetch(
      `funnel-insight/${funnelId}/lead-details`,
      {
        params: queryParams
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const fetchLeadServiceInsight = async (funnelId, queryParams = {}) => {
  try {
    const response = await axiosFetch(
      `funnel-insight/${funnelId}/lead-service`,
      {
        params: queryParams
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllCustomers = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer", { params: queryParams });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getCustomerById = async (customerId) => {
  try {
    const response = await axiosFetch(`customer/${customerId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getFunnelsReport = async (params = {}) => {
  return axiosFetch("funnel/performance-reports", { params });
};
export const getGroupedCustomerFunnelReport = async (customerId) => {
  return await axiosFetch(`customer/grouped-funnel-type-report/${customerId}`);
};
export const getCustomerPerformanceReport = async (
  customerId,
  queryParams = {}
) => {
  try {
    const response = await axiosFetch(`customer/funnel-report/${customerId}`, {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getCustomerInvoices = async (customerId, queryParams = {}) => {
  try {
    const response = await axiosFetch(`customer/invoices/${customerId}`, {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getCustInvoicesYears = async (customerId, queryParams = {}) => {
  try {
    const response = await axiosFetch(`customer/invoices-year/${customerId}`, {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getInvoicePdf = async (invoiceId, queryParams = {}) => {
  try {
    const response = await axiosFetch(`customer/invoice/${invoiceId}/pdf`, {
      params: queryParams,
      responseType: "blob"
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getCustomerReportByFunnelId = async (
  customerId,
  funnelId,
  queryParams = {}
) => {
  try {
    const response = await axiosFetch(
      `customer/single-funnel-report/${customerId}/${funnelId}`,
      {
        params: queryParams
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllLeads = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("lead-response", { params: queryParams });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllPartialLeads = async (params = {}) => {
  return axiosFetch("upcoming-lead-info", { params });
};
export const getLeadForAgent = (params = {}) => {
  return axiosFetch("lead-response/lead-agent", { params });
};
export const getLeadForAppointment = (params = {}) => {
  return axiosFetch("lead-response/lead-appointment", { params });
};
export const getLeadsBySearch = (params = {}) => {
  return axiosFetch("lead-response/lead-agent-search", { params });
};
export const getFunnelsForLeadsBySearch = (params = {}) => {
  return axiosFetch("lead-response/lead-agent-funnel-search", { params });
};
export const getCustomerByEmail = async (queryParams) => {
  try {
    const response = await axiosFetch("customer/availability", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllCategories = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("categories", { params: queryParams });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getCategoryByName = async (queryParams) => {
  try {
    const response = await axiosFetch("categories/availability", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getCategoryById = async (categoryId) => {
  try {
    const response = await axiosFetch(`categories/${categoryId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllCities = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("city", { params: queryParams });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getFbPages = async (config) => {
  try {
    const response = await axiosFetch("facebook/pages", config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getAllAssets = async (params = {}) => {
  return axiosFetch("asset", { params });
};
export const getSingleAsset = (assetId) => {
  return axiosFetch(`asset/${assetId}`);
};
export const getAllTags = async (params = {}) => {
  return axiosFetch("tag", { params });
};
export const getSingleTag = async (id) => {
  return axiosFetch(`tag/${id}`);
};
export const getTagByName = async (queryParams) => {
  return axiosFetch("tag/availability", {
    params: queryParams
  });
};
export const getBasicTags = async () => {
  return axiosFetch("tag/basic");
};
export const getFbForms = async (pageId, config) => {
  try {
    const response = await axiosFetch(`facebook/forms/${pageId}`, config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getLeadById = async (leadId) => {
  try {
    const response = await axiosFetch(`lead-response/${leadId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getUpcomingLeadById = async (upcomingLeadId) => {
  return await axiosFetch(`upcoming-lead-info/${upcomingLeadId}`);
};
export const getIntegrationsByFunnelId = async (funnelId) => {
  return axiosFetch(`integrations/${funnelId}`);
};
export const getAfterConfirmationIntegrationIdsByFunnel = async (funnelId) => {
  return axiosFetch(`integrations/${funnelId}/afterConfirmationIntegrations`);
};
export const getContactCloudProducts = async (tenantId) => {
  return axiosFetch(`integrations/contact-cloud-products/${tenantId}`);
};
export const getContactCloudUsers = async (tenantId) => {
  return axiosFetch(`integrations/contact-cloud-users/${tenantId}`);
};
export const getContactCloudJobs = async (tenantId) => {
  return axiosFetch(`integrations/contact-cloud-jobs/${tenantId}`);
};
export const getLeadActivities = async (leadId) => {
  return axiosFetch(`activity/${leadId}`);
};
export const getLeadPrice = async (categoryId) => {
  return axiosFetch(`categories/${categoryId}/get-lead-price`);
};
export const getSuggestions = (queryParams = {}) => {
  return axiosFetch("suggestion-gallery", {
    params: queryParams
  });
};
export const getOnHoldLeadsCount = (queryParams = {}) => {
  return axiosFetch("lead-response/on-hold-leads-count", {
    params: queryParams
  });
};
export const getLatestSystemHealthError = async () => {
  return axiosFetch("system-health-alert/get-latest");
};
export const getSystemHealthErrorByApi = async (params = {}) => {
  return axiosFetch("system-health-alert/get-error-by-api", { params });
};
export const getNonRejectedRawHookData = async () => {
  return axiosFetch("fb-hook-raw-data/non-rejected");
};
export const getFunnelLandingPage = (params = {}) => {
  return axiosFetch("landing-page", { params });
};
export const getFunnelsCount = (params = {}) => {
  return axiosFetch("funnel/get-count", { params });
};
export const getLeadsCount = (params = {}) => {
  return axiosFetch("lead-response/get-count", { params });
};

// "DOWNLOAD" Requests
export const downloadLeadsAsCsv = async (queryParams = {}) => {
  return axiosDownload({ url: "lead-response/download", queryParams });
};

// "POST" Requests
export const loginUser = async (body) => {
  return axiosPost(authConfig.loginEndpoint, body);
};
export const generateOTP = () => {
  return axiosPost("user/otp/generate/authenticator");
};
export const generateOTPViaService = (payload) => {
  return axiosPost("user/otp/generate/email", payload);
};
export const setupAuthenticator = (payload) => {
  return axiosPost("user/otp/setup/authenticator", payload);
};
export const setupEmailVerification = (payload) => {
  return axiosPost("user/otp/setup/email", payload);
};
export const createUser = (body) => {
  return axiosPost("user", body);
};
// export const assignUserToken = (body) => {
//   return axiosPost(`site-settings`, body);
// };
export const changepassword = (body) => {
  return axiosPost("user/change-password", body);
};
export const createActivity = (body, id) => {
  return axiosPost(`activity/${id}`, body);
};
export const resetPasswordToken = async (body) => {
  return axiosPost("user/reset-password-token", body);
};
export const resetPassword = async (body) => {
  return axiosPost("user/reset-password", body);
};
export const createDraftFunnel = async () => {
  try {
    const response = await axiosPost("funnel/draft", {});
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createDraftTemplate = async () => {
  try {
    const response = await axiosPost("template/draft", {});
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const uploadFunnelAssets = async (payload) => {
  try {
    const response = await axiosPost("funnel/upload-assets", payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const checkVersionTitleAvailability = async (funnelId, payload) => {
  return axiosPost(`versions/${funnelId}/check-version-title`, payload);
};
export const changeVersionById = async (funnelId, payload) => {
  try {
    const response = await axiosPost(
      `funnel/${funnelId}/change-version`,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const integrateFunnel = async (funnelId, payload) => {
  try {
    const response = await axiosPost(`integrations/${funnelId}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createAssetEntry = async (payload) => {
  try {
    const response = await axiosPost("asset", payload);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createCustomer = async (payload) => {
  try {
    const response = await axiosPost("customer", payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createCustomerWebTemplate = async (customerId, payload) => {
  try {
    const response = await axiosPost(
      `customer/${customerId}/create-template`,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const duplicateCustomerWebTemplate = async (customerId, payload) => {
  try {
    const response = await axiosPatch(
      `customer/${customerId}/duplicate-template`,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerAllWebTemplates = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer/get-all-templates", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerTemplateSections = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer/template/sections", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPixabayImages = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer/pixabay-images", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerActiveWebsiteTemplate = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer/get-active-template", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerActiveWebsiteDomain = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer/website/domain", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCustomerWebsiteInfo = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer/get-template", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDnsRecord = async (queryParams = {}) => {
  try {
    const response = await axiosFetch("customer/website/dns-record", {
      params: queryParams
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveCustomerWebTemplate = async (customerId, payload) => {
  try {
    const response = await axiosPatch(
      `customer/${customerId}/save-template`,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveCustomerWebDomain = async (customerId, payload) => {
  try {
    const response = await axiosPatch(
      `customer/${customerId}/save-website-domain`,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTemplateDraft = async (customerId) => {
  try {
    const response = await axiosDelete(`customer/${customerId}/delete-draft`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const duplicateFunnel = async (funnelId, payload) => {
  try {
    const response = await axiosPost(`funnel/${funnelId}/duplicate`, payload);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createCategory = async (payload) => {
  try {
    const response = await axiosPost("categories", payload);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const syncManually = async (payload) => {
  try {
    const response = await axiosPost("lead-response/sync", payload);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createCity = async (payload) => {
  try {
    const response = await axiosPost("city", payload);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const loadToken = async (payload) => {
  try {
    const response = await axiosPost("facebook/update-token", payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const loadClickfunnelHtml = async (importUrl) => {
  try {
    const response = await axiosPost(
      `funnel/load-clickfunnel-html`,
      {
        url: importUrl
      },
      { responseType: "document" }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const createTag = (payload) => {
  return axiosPost("tag", payload);
};
export const isAdValid = (adId) => {
  return axiosPost(`facebook/check-ad-validity/${adId}`);
};
export const createAdCopies = (payload) => {
  return axiosPost("ad-copy", payload);
};
export const validateContactCloudId = (payload) => {
  return axiosPost("customer/validate-contact-cloud-id", payload);
};

export const upoadImageToFacebook = (adAccountId, formData, config) => {
  return axiosPost(
    `facebook/facebook-upload-image/${adAccountId}`,
    formData,
    config
  );
};

export const duplicateAd = (payload) => {
  return axiosPost(`facebook/duplicate-ad`, payload);
};

export const updateMissingCategoryInfo = (payload) => {
  return axiosPost(`missing-info/category`, payload);
};
export const updateMissingCustomerInfo = (payload) => {
  return axiosPost(`missing-info/customer`, payload);
};
export const updateMissingHubspotInfo = (payload) => {
  return axiosPost(`missing-info/hubspot`, payload);
};
export const updateMissingAdAccountInfo = (payload) => {
  return axiosPost(`missing-info/ad-account`, payload);
};

export const updateMissingLeadInfo = (payload) => {
  return axiosPost(`missing-info/leads`, payload);
};
export const uploadSuggestion = (payload) => {
  return axiosPost("suggestion-gallery", payload);
};
export const createFunnelLandingPage = async (payload) => {
  return axiosPost("landing-page", payload);
};
export const sendInvoiceToCustViaEmail = (customerId, invoiceId, payload) => {
  return axiosPost(
    `customer/${customerId}/send-email-invoice/${invoiceId}`,
    payload
  );
};

// "PATCH" Requests
export const updateAsset = async (payload, assetId) => {
  try {
    const response = await axiosPatch(`asset/${assetId}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateLeadById = (id, body) => {
  return axiosPatch(`lead-response/${id}`, body);
};
export const updateLeadAdAssociation = (id, action, body) => {
  return axiosPatch(
    `lead-response/${id}/update-ad-association/${action}`,
    body
  );
};
export const saveFbForm = async (funnelId, payload) => {
  try {
    const response = await axiosPatch(
      `funnel/save-fb-form/${funnelId}`,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
// export const updateUserToken = (userId, body) => {
//   return axiosPatch(`site-settings/${userId}`, body);
// };
export const setPrimaryToken = (userId) => {
  return axiosPatch(`site-settings/${userId}/primary`);
};
export const updateFunnel = async (funnelId, payload) => {
  try {
    const response = await axiosPatch(`funnel/${funnelId}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateFunnelFilters = async (funnelId, payload) => {
  try {
    const response = await axiosPatch(`funnel/${funnelId}/filter`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateFunnelLandingPage = async (funnelId, payload) => {
  return axiosPatch(`funnel/${funnelId}/update-landing-page-values`, payload);
};
export const toggleLandingPageEnable = async (funnelId, payload) => {
  return axiosPatch(`funnel/${funnelId}/toggle-landing-page-enable`, payload);
};
export const handleLandingPageForVersionChange = async (funnelId, payload) => {
  return axiosPatch(
    `funnel/${funnelId}/update-landing-page-on-version-change`,
    payload
  );
};
export const updateTemplate = async (templateId, payload) => {
  try {
    const response = await axiosPatch(`template/${templateId}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const archiveFunnel = async (funnelId) => {
  try {
    const response = await axiosPatch(`funnel/${funnelId}/archive`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const unarchiveFunnel = async (funnelId, payload) => {
  try {
    const response = await axiosPatch(`funnel/${funnelId}/unarchive`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const archiveLead = async (leadId, payload) => {
  try {
    const response = await axiosPatch(
      `lead-response/${leadId}/archive`,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateCustomer = async (customerId, payload) => {
  try {
    const response = await axiosPatch(`customer/${customerId}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateCategory = async (categoryId, payload) => {
  try {
    const response = await axiosPatch(`categories/${categoryId}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateUser = async (payload) => {
  try {
    const response = await axiosPatch(`user/me/update`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateIntegration = async (integrationId, payload) => {
  try {
    const response = await axiosPatch(`integrations/${integrationId}`, payload);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updateUserById = (body, id) => {
  return axiosPatch(`user/${id}`, body);
};
export const toggleLeadService = (body, id) => {
  return axiosPatch(`funnel/${id}/toggle-lead-service`, body);
};
export const toggleLeadServicePlus = (body, id) => {
  return axiosPatch(`funnel/${id}/toggle-lead-service-plus`, body);
};
export const toggleAlternativeAddress = (body, id) => {
  return axiosPatch(`funnel/${id}/toggle-alternative-address`, body);
};
export const updateAlternativeAddress = (body, id) => {
  return axiosPatch(`funnel/${id}/update-alternative-address`, body);
};
export const handleCustomerAppointmentLink = (id, body) => {
  return axiosPatch(`funnel/${id}/appointment-link`, body);
};
export const updateTag = (id, payload) => {
  return axiosPatch(`tag/${id}`, payload);
};
export const updateIntegrations = ({ funnelId, integrationType }, payload) => {
  return axiosPatch(`integrations/${funnelId}/${integrationType}`, payload);
};
export const updateLeadBlockTime = (payload) => {
  return axiosPatch(`lead-response/lead-block-time`, payload);
};
export const resetLead = (leadId, payload = {}) => {
  return axiosPatch(`lead-response/reset/${leadId}`, payload);
};
export const updateCompanyNameForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-company-name-for-agent`, body);
};
export const updateProductNameForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-product-name-for-agent`, body);
};
export const updatePricePerInvoiceUnitForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-price-for-agent`, body);
};
export const updateFixedPricePerInvoiceUnitForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-fixed-price-for-agent`, body);
};
export const updateNameOfAccountingUniForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-unit-for-agent`, body);
};
export const updateBudgetQueriesForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-budget-queries-for-agent`, body);
};
export const updatePricesPerAreaForAgent = (funnelId, body) => {
  return axiosPatch(
    `funnel/${funnelId}/update-prices-per-area-for-agent`,
    body
  );
};
export const updateLeadServicePlusPriceTypeForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-price-type-for-agent`, body);
};
export const updateLeadServiceQuestionForAgent = (funnelId, body) => {
  return axiosPatch(`funnel/${funnelId}/update-questions-for-agent`, body);
};
export const updateCampaignOptimizationTask = (id, body) => {
  return axiosPatch(`funnels-evaluation/optimization/${id}`, body);
};
export const validateAndUpdateLeadAgentInfo = (leadId, payload = {}) => {
  return axiosPatch(
    `lead-response/${leadId}/validate-and-update-agent`,
    payload
  );
};
export const ignoreMissingLeadInfo = (payload) => {
  return axiosPatch(`missing-info/leads/ignore`, payload);
};
export const updateSuggestion = (id, payload) => {
  return axiosPatch(`suggestion-gallery/${id}`, payload);
};
export const archiveOrUnarchiveMultipleSuggestions = (payload) => {
  return axiosPatch(
    `suggestion-gallery/${
      payload.shouldArchive ? "archive" : "unarchive"
    }-multiple`,
    payload
  );
};
export const archiveOrUnarchiveSuggestion = (id, payload) => {
  return axiosPatch(
    `suggestion-gallery/${id}/${
      payload.shouldArchive ? "archive" : "unarchive"
    }`,
    payload
  );
};
export const setIsWinningSuggestion = (id, payload) => {
  return axiosPatch(`suggestion-gallery/${id}/is-winning`, payload);
};
export const handleAdvertisingAccountLink = (id, body) => {
  return axiosPatch(`funnel/${id}/advertising-account-link`, body);
};
export const changeAgentScript = (payload) => {
  return axiosPatch("leadservice-panel/change-script", payload);
};
export const syncNowOnDisableLeadservice = (integrationIds) => {
  return axiosPatch("lead-response/sync-now-on-disable-leadservice", {
    integrationIds
  });
};
export const updateVersionLandingPageId = async (versionId, payload) => {
  return axiosPatch(`versions/${versionId}/update-landing-page-id`, payload);
};
export const updateAppointmentEmailTemplates = async (payload) => {
  return axiosPatch("leadservice-panel/email-template", payload);
};

// "DELETE Requests"
export const disableAuthenticatorVerification = (payload) => {
  return axiosDelete("user/otp/setup/authenticator", payload);
};
export const disableEmailVerification = (payload) => {
  return axiosDelete("user/otp/setup/email", payload);
};
export const deleteTemplate = async (templateId) => {
  try {
    const response = await axiosDelete(`template/${templateId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteAdImage = async (adAccountId, hash) => {
  return await axiosDelete(`facebook/delete-image/${adAccountId}/${hash}`);
};
export const removeIntegration = async (integrationId) => {
  try {
    const response = await axiosDelete(`integrations/${integrationId}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const deleteCategory = (id) => {
  return axiosDelete(`categories/${id}`);
};
export const deleteUpcomingLeadById = async (upcomingLeadId) => {
  return await axiosDelete(`upcoming-lead-info/${upcomingLeadId}`);
};

// export const deleteUserToken = (id) => {
//   return axiosDelete(`site-settings/${id}`);
// };
