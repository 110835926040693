import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomCard, FieldSet, Legend } from "../../common-styling";
import { Col, Form, Row } from "react-bootstrap";
import ColorPickerWithTextField from "../../reusable-form-fields/color-picker-with-text-field";
import SelectField from "../../reusable-form-fields/select-field";
import TextField from "../../reusable-form-fields/text-field";
import FileInput from "../../reusable-form-fields/input-file";

const HeaderSection = ({
  logoTypes,
  parseResourceSrc,
  parsePreviewResourceSrc,
  sections,
  isFormChanged
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    register,
    getValues
  } = useFormContext();

  const navbarLogoType = watch("navbar.logo.type");
  const navbarLogoImage = watch("navbar.logo.image");
  const NavbarBGColorDisplay = watch("navbar.bgColorDisplay");

  const navbarBgColorPickerRef = useRef();
  const navbarLogoColorPickerRef = useRef();
  const navbarLinksColorPickerRef = useRef();
  const phoneColorPickerRef = useRef();
  const addressColorPickerRef = useRef();

  return (
    <CustomCard className="mb-3 py-1">
      <FieldSet>
        <Row>
          <Col md={12}>
            <Legend>
              <h4 className="mb-0 fw-bold">{t("customerWebsite.header")}</h4>
            </Legend>
          </Col>
          <Col md={3} className="mt-1">
            <ColorPickerWithTextField
              label={t("customerWebsite.BGColor")}
              fieldName="navbar.bgColor"
              fieldError={errors?.navbar?.bgColor}
              fieldRef={navbarBgColorPickerRef}
              fieldVal={watch("navbar.bgColor")}
              displayFieldWatcher={NavbarBGColorDisplay}
              fieldWatcherName="navbar.bgColorDisplay"
              isDisabled={Boolean(!NavbarBGColorDisplay)}
              setDisplay
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              isFormChanged={isFormChanged}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Legend>
              <h6 className="mb-0 fw-bold mb-3">
                {t("customerWebsite.logo.heading")}
              </h6>
            </Legend>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={navbarLogoType?._id === logoTypes[0]._id ? 4 : 6}>
            <Form.Group className="mb-4" controlId="land">
              <Form.Label>{t("customerWebsite.logo.logoType")}</Form.Label>
              <SelectField
                control={control}
                name="navbar.logo.type"
                options={logoTypes}
                getValues={getValues}
                errors={errors?.navbar?.logo?.type}
              />
            </Form.Group>
          </Col>

          {navbarLogoType?._id === logoTypes[0]._id ? (
            <>
              <Col sm={12} md={4}>
                <TextField
                  label={t("customerWebsite.logo.logoText")}
                  placeholder="Logo Text"
                  type="text"
                  name="navbar.logo.text"
                  errors={errors?.navbar?.logo?.text}
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={4}>
                <ColorPickerWithTextField
                  label={t("customerWebsite.logo.logoColor")}
                  placeholder="#000000"
                  fieldName="navbar.logo.color"
                  fieldError={errors?.navbar?.logo?.color}
                  fieldRef={navbarLogoColorPickerRef}
                  fieldVal={watch("navbar.logo.color")}
                  defaultColorValue="#000000"
                  isInGroup
                  noSpacing
                  hideErrMsg
                  hideButton
                  isFormChanged={isFormChanged}
                />
              </Col>
            </>
          ) : navbarLogoType?._id === logoTypes[1]._id ? (
            <Col xs={12} md={6}>
              <FileInput
                label={t("customerWebsite.logo.logoImage")}
                name="navbar.logo.image"
                // resourceId={resourceId}
                parentFolderName={"customer"}
                url={parseResourceSrc(navbarLogoImage)}
                urlForPreview={parsePreviewResourceSrc(navbarLogoImage)}
                asset={navbarLogoImage?.asset}
                error={errors?.navbar?.logo?.image}
                isFormChanged={isFormChanged}
                // selectedCategory={getValues("category")}
                // tagName="backgroundimage"
              />
            </Col>
          ) : null}
        </Row>
        <Row className="mt-2">
          <Col md={12}>
            <Legend>
              <h6 className="mb-0 fw-bold mb-1">
                {t("customerWebsite.navLinks")}
              </h6>
            </Legend>
          </Col>
          <Col md={3}>
            <div>
              <ColorPickerWithTextField
                label={t("customerWebsite.linksTextColor")}
                placeholder="#000000"
                fieldName="navbar.links.color"
                fieldError={errors?.navbar?.links?.color}
                fieldRef={navbarLinksColorPickerRef}
                fieldVal={watch("navbar.links.color")}
                defaultColorValue="#000000"
                isInGroup
                noSpacing
                hideErrMsg
                hideButton
                isFormChanged={isFormChanged}
              />
            </div>
          </Col>
          <Col md={12}>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #1 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #1 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="navbar.links.links[0].text"
                  errors={
                    errors?.navbar?.links?.links &&
                    errors?.navbar?.links?.links[0]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #1 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="navbar.links.links[0].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.navbar?.links?.links &&
                      errors?.navbar?.links?.links[0]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #2 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #2 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="navbar.links.links[1].text"
                  errors={
                    errors?.navbar?.links?.links &&
                    errors?.navbar?.links?.links[1]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #2 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="navbar.links.links[1].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.navbar?.links?.links &&
                      errors?.navbar?.links?.links[1]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #3 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #3 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="navbar.links.links[2].text"
                  errors={
                    errors?.navbar?.links?.links &&
                    errors?.navbar?.links?.links[2]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #3 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="navbar.links.links[2].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.navbar?.links?.links &&
                      errors?.navbar?.links?.links[2]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 bg-light py-2 mx-1 border">
              <Col sm={12} md={6}>
                <TextField
                  label={`${t("customerWebsite.link")} #4 ${t(
                    "customerWebsite.text"
                  )}`}
                  placeholder={`${t("customerWebsite.link")} #4 ${t(
                    "customerWebsite.text"
                  )}`}
                  type="text"
                  name="navbar.links.links[3].text"
                  errors={
                    errors?.navbar?.links?.links &&
                    errors?.navbar?.links?.links[3]?.text
                  }
                  register={register}
                  noSpacing
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="land">
                  <Form.Label>{`${t("customerWebsite.link")} #4 ${t(
                    "customerWebsite.link"
                  )}`}</Form.Label>
                  <SelectField
                    control={control}
                    name="navbar.links.links[3].link"
                    options={sections}
                    getValues={getValues}
                    errors={
                      errors?.navbar?.links?.links &&
                      errors?.navbar?.links?.links[3]?.link
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Legend>
              <h6 className="mb-0 fw-bold mb-3">
                {t("customerWebsite.contact")}
              </h6>
            </Legend>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <TextField
              label={t("customerWebsite.phone")}
              placeholder={t("customerWebsite.phone")}
              type="text"
              name="navbar.phone.text"
              errors={errors?.navbar?.phone?.text}
              register={register}
              noSpacing
            />
          </Col>
          <Col xs={12} md={3}>
            <ColorPickerWithTextField
              label={t("customerWebsite.phoneIconColor")}
              placeholder="#000000"
              fieldName="navbar.phone.color"
              fieldError={errors?.navbar?.phone?.color}
              fieldRef={phoneColorPickerRef}
              fieldVal={watch("navbar.phone.color")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              isFormChanged={isFormChanged}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextField
              label={t("customerWebsite.address")}
              placeholder={t("customerWebsite.address")}
              type="text"
              name="navbar.address.text"
              errors={errors?.navbar?.address?.text}
              register={register}
              noSpacing
            />
          </Col>
          <Col xs={12} md={3}>
            <ColorPickerWithTextField
              label={t("customerWebsite.addressIconColor")}
              placeholder="#000000"
              fieldName="navbar.address.color"
              fieldError={errors?.navbar?.address?.color}
              fieldRef={addressColorPickerRef}
              fieldVal={watch("navbar.address.color")}
              defaultColorValue="#000000"
              isInGroup
              noSpacing
              hideErrMsg
              hideButton
              isFormChanged={isFormChanged}
            />
          </Col>
        </Row>
      </FieldSet>
    </CustomCard>
  );
};

export default HeaderSection;
